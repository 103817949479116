import BACKEND_DOMAIN from "../../service";

const API_URL = `${BACKEND_DOMAIN}/business/merchant/branch/create`;
export const uploadBranchRequest = () => ({
    type: "UPLOAD_BRANCH_REQUEST", // Replace with your actual action type
});

export const uploadBranchSuccess = (data) => ({
    type: "UPLOAD_BRANCH_SUCCESS", // Replace with your actual action type
    payload: data,
});

export const uploadBranchFailure = (error) => ({
    type: "UPLOAD_BRANCH_FAILURE", // Replace with your actual action type
    payload: error,
});

export const uploadBranch = (branchData, token) => async (dispatch) => {
    try {
        dispatch(uploadBranchRequest());
        const formData = new FormData();
        formData.append("sub_name", branchData.sub_name);
        formData.append("sub_profile_image", branchData.sub_profile_image);
        formData.append("sub_cover_image", branchData.sub_cover_image);
        formData.append("sub_contact_person", branchData.sub_contact_person);
        formData.append("sub_phone_number", branchData.sub_phone_number);
        formData.append("sub_alt_phone_number", branchData.sub_alt_phone_number);
        formData.append("sub_email", branchData.sub_email);
        formData.append("sub_location", branchData.sub_location);
        formData.append("sub_kra_pin", branchData.kra_pin);
        formData.append("sub_password", branchData.sub_password);
        const response = await fetch(API_URL, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        });

        if (!response.ok) {
            throw new Error("Error adding product");
        }

        const data = await response.json();
        dispatch(uploadBranchSuccess(data));
    } catch (error) {
        dispatch(uploadBranchFailure(error.message));
    }
};
