import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";
import { fetchProducts, fetchCategories } from "./data_fetching/dashboardData";

function SalesAnalytics({ isAdmin }) {
    const [isNew, setIsNew] = useState(true);
    const [options, setOptions] = useState({
        series: [0],
        labels: ["Default Label"],
        plotOptions: {
            pie: {
                expandOnClick: true,
                donut: {
                    size: "70%",
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            showAlways: true,
                            fontSize: 14,
                        },
                    },
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            position: "bottom",
        },
    });

    const authToken = useSelector((state) => state.Login.token);

    //This function extracts the number of products in each category and loads the series data:
    const autofillSeries = async (token, categoryList) => {
        try {
            const products = await fetchProducts(token);

            if (products.length > 0) {
                setIsNew(false);
            }

            let productsMap = {};
            for (let item of categoryList) {
                productsMap[item] = 0;
            }

            products.map((product) => productsMap[product.category_name]++);

            const seriesData = Object.values(productsMap);

            setOptions((prevOptions) => ({
                ...prevOptions,
                series: seriesData,
            }));
        } catch (e) {
            console.error("Unable to fetch products -> ", e);
        }
    };

    const getCategoriesForLabels = async (token) => {
        try {
            const categories = await fetchCategories(token);

            const categoryLabels = categories.map((item) => item.name);
            setOptions((prevOptions) => ({
                ...prevOptions,
                labels: categoryLabels,
            }));

            await autofillSeries(token, categoryLabels);
        } catch (e) {
            console.error("Unable to fetch categories -> ", e);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await getCategoriesForLabels(authToken);
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken]);

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">Product Analytics</h4>

                    {isAdmin || isNew ? (
                        <div style={{ height: 270, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <h6 className="text-center p-3" style={{ fontSize: "12px" }}>
                                {isAdmin ? "Coming Soon..." : "Loading..."}
                            </h6>
                        </div>
                    ) : (
                        <div id="donut-chart" className="apex-charts mb-2">
                            <ReactApexChart options={options} series={options.series} type="donut" height="270" />
                        </div>
                    )}
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default SalesAnalytics;
