import React, { useEffect, useMemo, useState } from "react";
import TableContainer from "../../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Container } from "reactstrap";
import { useSelector } from "react-redux";
import { fetchUsers } from "../utilities/datafetching";
import dateFormatter from "../../Orders/utilities/dateFormatter";

const User = () => {
    const authToken = useSelector((state) => state.Login.token);

    const [allUsersList, setAllUsersList] = useState([{}]);
    const [usersListEmpty, setUsersListEmpty] = useState(false);

    const fetchAllUsers = async (token) => {
        const results = await fetchUsers(token);

        if (results?.length === 0) {
            setUsersListEmpty(true);
        }
        setAllUsersList(results);
    };

    useEffect(() => {
        fetchAllUsers(authToken);
    }, [authToken]);

    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "first_name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Role",
                accessor: "role",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Email",
                accessor: "email",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Phone Number",
                accessor: "phone_number",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Date Created",
                accessor: "created_at",
                Cell: ({ cell: { value } }) => {
                    const finalDate = dateFormatter(value);
                    return finalDate;
                    // return value;
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Status",
                accessor: "is_active",
                Cell: ({ cell: { value } }) => {
                    let status = value;
                    if (status === true) {
                        return "Active";
                    } else if (status === false) {
                        return "Inactive";
                    } else {
                        return "";
                    }
                },
                disableFilters: true,
                filterable: false,
            },
        ],
        []
    );

    const breadcrumbItems = [
        { title: "User Management", link: "/" },
        { title: "All Users", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Platform Users" breadcrumbItems={breadcrumbItems} />
                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={allUsersList || []}
                                isPagination={false}
                                iscustomPageSize={false}
                                isBordered={false}
                                customPageSize={10}
                                isEmpty={usersListEmpty}
                                isEmptyContent="There are no users currently."
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default User;
