import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "../../../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { Button, Card, CardBody, Container, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import BACKEND_DOMAIN from "../../../../service";

const MerchantBranchList = () => {
    const [branches, setBranches] = useState([]);
    const [noBranches, setNoBranches] = useState(false);

    const authToken = useSelector((state) => state.Login.token);

    const [deleteModal, setDeleteModal] = useState(false);
    const [branchIdToDelete, setBranchIdToDelete] = useState(null);
    const [deletionSuccessMessage, setDeletionSuccessMessage] = useState("");

    const toggleDeleteModal = () => setDeleteModal(!deleteModal);

    const deleteBranch = (branchId) => {
        setBranchIdToDelete(branchId);
        toggleDeleteModal();
    };

    const confirmDelete = () => {
        if (branchIdToDelete) {
            const branchDeletionAPIUrl = `${BACKEND_DOMAIN}/business/merchant/branch/delete/${branchIdToDelete}`;

            fetch(branchDeletionAPIUrl, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    if (response.ok) {
                        const updatedBranches = branches.filter((branch) => branch.id !== branchIdToDelete);
                        setBranches(updatedBranches);
                        setDeletionSuccessMessage("Branch deleted successfully!");
                        setTimeout(() => {
                            setDeletionSuccessMessage("");
                        }, 3000);
                    } else {
                        console.error("Error deleting branch: ", response.status);
                    }
                })
                .catch((error) => console.error("Error deleting branch: ", error))
                .finally(() => {
                    setBranchIdToDelete(null);
                    toggleDeleteModal();
                });
        }
    };

    const apiUrlBranch = `${BACKEND_DOMAIN}/business/merchant/branch/list`;

    const fetchData = async (token) => {
        try {
            const response = await axios.get(apiUrlBranch, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });
            if (response.data.data.length === 0) {
                setNoBranches(true);
            }
            setBranches(response.data.data);
        } catch (e) {
            console.log("Error fetching branches -> ", e);
        }
    };

    useEffect(() => {
        fetchData(authToken);
    }, [authToken]);

    const columns = useMemo(() => [
        {
            Header: "Branch Name",
            accessor: "sub_name",
            disableFilters: true,
            filterable: false,
        },
        {
            Header: "Location",
            accessor: "sub_location",
            disableFilters: true,
            filterable: false,
        },
        {
            Header: "Contact Person",
            accessor: "sub_contact_person",
            disableFilters: true,
            filterable: false,
        },
        {
            Header: "Email",
            accessor: "sub_email",
            disableFilters: true,
            filterable: false,
        },
        {
            Header: "Date",
            accessor: "sub_date_created",
            Cell: ({ cell: { value } }) => {
                const datePart = value.substring(0, 10);
                return datePart;
            },
            disableFilters: true,
            filterable: false,
        },
        {
            Header: "Wallet Balance",
            accessor: "sub_wallet_balance",
            disableFilters: true,
            filterable: false,
        },
        {
            Header: "Action",
            accessor: (cellProps) => {
                const branchId = cellProps.id;

                return (
                    <React.Fragment>
                        <Link to={`/route-money/merchant/branch/edit/${branchId}`} className="me-3 text-secondary">
                            <i className="mdi mdi-pencil font-size-18"></i>
                        </Link>
                        <Link to="#" className="me-3 text-danger" onClick={() => deleteBranch(branchId)}>
                            <i className="mdi mdi-delete font-size-18"></i>
                        </Link>
                    </React.Fragment>
                );
            },
            disableFilters: true,
            filterable: false,
        },
    ]);

    const breadcrumbItems = [
        { title: "Branches", link: "/" },
        { title: "Branch List", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Branch List" breadcrumbItems={breadcrumbItems} />
                    <Link to="/route-money/merchant/branch/add" className="btn btn-primary mb-3">
                        Add Branch
                    </Link>

                    {deletionSuccessMessage && <Alert color="success">{deletionSuccessMessage}</Alert>}
                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={branches || []}
                                isPagination={false}
                                iscustomPageSize={false}
                                isBordered={false}
                                customPageSize={10}
                                isEmpty={noBranches}
                                isEmptyContent="No branches have been added yet."
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
            <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
                <ModalHeader toggle={toggleDeleteModal}>Confirm Delete</ModalHeader>
                <ModalBody>Are you sure you want to delete this branch?</ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleDeleteModal}>
                        Cancel
                    </Button>
                    <Button color="danger" onClick={confirmDelete}>
                        Confirm
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default MerchantBranchList;
