import React, { Component } from "react";
import { Row, Col, Card, CardBody, TabContent, TabPane, Label, Input, Form, Container } from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

class CreateUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "User Management", link: "#" },
                { title: "Create User", link: "#" },
            ],
            activeTab: 1,
            activeTabProgress: 1,
            progressValue: 25,
        };
        this.toggleTab.bind(this);
        this.toggleTabProgress.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                this.setState({
                    activeTab: tab,
                });
            }
        }
    }

    toggleTabProgress(tab) {
        if (this.state.activeTabProgress !== tab) {
            if (tab >= 1 && tab <= 4) {
                this.setState({
                    activeTabProgress: tab,
                });

                if (tab === 1) {
                    this.setState({ progressValue: 25 });
                }
                if (tab === 2) {
                    this.setState({ progressValue: 50 });
                }
                if (tab === 3) {
                    this.setState({ progressValue: 75 });
                }
                if (tab === 4) {
                    this.setState({ progressValue: 100 });
                }
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Create User" breadcrumbItems={this.state.breadcrumbItems} />

                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                            <TabContent
                                                activeTab={this.state.activeTab}
                                                className="twitter-bs-wizard-tab-content"
                                            >
                                                <TabPane tabId={1}>
                                                    <Form>
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="basicpill-firstname-input1"
                                                                    >
                                                                        First name
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="basicpill-firstname-input1"
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="basicpill-lastname-input2"
                                                                    >
                                                                        Last name
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="basicpill-lastname-input2"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="basicpill-phoneno-input3"
                                                                    >
                                                                        Phone
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="basicpill-phoneno-input3"
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="basicpill-email-input4"
                                                                    >
                                                                        Email
                                                                    </Label>
                                                                    <Input
                                                                        type="email"
                                                                        className="form-control"
                                                                        id="basicpill-email-input4"
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="12">
                                                                <div className="mb-3">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="basicpill-address-input1"
                                                                    >
                                                                        Address
                                                                    </Label>
                                                                    <textarea
                                                                        id="basicpill-address-input1"
                                                                        className="form-control"
                                                                        rows="2"
                                                                    ></textarea>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </TabPane>
                                            </TabContent>
                                            <ul className="pager wizard twitter-bs-wizard-pager-link">
                                                <li
                                                    className={
                                                        this.state.activeTab === 1 ? "previous disabled" : "previous"
                                                    }
                                                >
                                                    <Link
                                                        to="#"
                                                        onClick={() => {
                                                            this.toggleTab(this.state.activeTab - 1);
                                                        }}
                                                    >
                                                        Previous
                                                    </Link>
                                                </li>
                                                <li className={this.state.activeTab === 4 ? "next disabled" : "next"}>
                                                    <Link
                                                        to="#"
                                                        onClick={() => {
                                                            this.toggleTab(this.state.activeTab + 1);
                                                        }}
                                                    >
                                                        Submit
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default CreateUser;
