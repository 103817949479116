import { takeEvery, put, all, fork } from "redux-saga/effects";
import { REGISTER_USER } from "./actionTypes";
import { registerUserFailed, registerUserSuccessful } from "./actions";

export function* registerUser({ payload }) {
    try {
        yield put(registerUserSuccessful(payload));
    } catch (error) {
        yield put(registerUserFailed(error));
    }
}

export function* watchUserRegister() {
    yield takeEvery(REGISTER_USER, registerUser);
}

function* accountSaga() {
    yield all([fork(watchUserRegister)]);
}

export default accountSaga;
