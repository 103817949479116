import React, { useState, useEffect } from "react";
import "firebase/compat/auth";
import { Row, Col, Button, Alert, Container, Label } from "reactstrap";
import { Card, CardBody, TabContent, TabPane, NavItem, NavLink, Input, Form } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import classnames from "classnames";
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light-new.jpg";
import { registerUserStart, registerUserSuccessful, registerUserFailed } from "../../store/actions";
import validateRequiredFields from "./utilities/registrationFormValidation";
import Select from "react-select";
import businessTypes from "./utilities/businessTypes";
import { specifiedBusinessTypes } from "./utilities/businessTypes";
import BACKEND_DOMAIN from "../../service";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const SEND_OTP_URL = `${BACKEND_DOMAIN}/users/send-code`;
const VERIFY_OTP_URL = `${BACKEND_DOMAIN}/users/verify-otp`;

// React PhoneInput custom styles
const customInputStyle = {
    width: "100%",
    height: "40px",
    backgroundColor: "inherit",
    border: "1px solid #cacaca",
    marginTop: "50px",
};

const customDropdownStyle = {
    backgroundColor: "inherit",
    borderRadius: 0,
    boxShadow: "none",
    border: "1px solid transparent",
    color: "inherit",
    height: "fit-content",
    marginTop: 0,
    marginLeft: "-2px",
};

// React Select input field Custom styles
const colourStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: "inherit",
        borderColor: "transparent",
        color: "inherit",
        margin: "-7.5px -12px",
        ":hover": {
            borderColor: "transparent",
        },
    }),
    placeholder: (styles) => ({
        ...styles,
        color: "inherit",
    }),
    input: (styles) => ({
        ...styles,
        color: "inherit",
    }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: "inherit",
        margin: "8px -14px",
        width: "101%",
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isDisabled ? null : isSelected ? "inherit" : isFocused ? "#5664d2" : null,
            color: isDisabled ? null : isSelected ? "#5664d2" : "inherit",
            ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled ? (isSelected ? "#5664d2" : "#5664d2") : null,
            },
        };
    },
};

const Register = (props) => {
    const [otp, setOtp] = useState("");
    const [otpSentMessage, setOtpSentMessage] = useState("");
    const [otpVerifiedMessage, setOtpVerifiedMessage] = useState("");
    const [otpVerificationSuccessful, setOtpVerificationSuccessful] = useState(false);
    const [registrationError, setRegistrationError] = useState(null);
    const [userRegistrationSuccessful, setUserRegistrationSuccessful] = useState(false);
    const [registrationFailedErrorMessage, setRegistrationFailedErrorMessage] = useState(null);

    // Loading spinners for said actions:
    const [isSendingOTP, setIsSendingOTP] = useState(false);
    const [isVerifyingOTP, setIsVerifyingOTP] = useState(false);
    const [isSubmittingRegistration, setIsSubmittingRegistration] = useState(false);

    const [allFormData, setAllFormData] = useState({
        pane1: {
            name: "",
            contact_person: "",
            phone_number: "",
            alt_phone_number: "",
            location: "",
            email: "",
        },
        pane2: {
            image: "",
            kra_pin: "",
            profile_image: "",
            cover_image: "",
        },
        pane3: {
            otp: "",
        },
        pane4: {
            type: "",
            specific_type: "",
            password: "",
            confirmPassword: "",
        },
    });

    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1);
    const [activeTabProgress, setActiveTabProgress] = useState(1);
    // eslint-disable-next-line
    const [progressValue, setProgressValue] = useState(25);
    // const formRef = useRef(null);
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [passwordVisible, setPasswordVisible] = useState(false);

    // Temp value holder for the business type:
    const [selectedBusinessType, setSelectedBusinessType] = useState(null);
    const [selectedSpecBusinessType, setSelectedSpecBusinessType] = useState(null);
    const [specBusinessTypeOptions, setSpecBusinessTypeOptions] = useState([]);

    // OTP sending and verifying error Message:
    const [otpNotSentError, setOtpNotSentError] = useState(null);
    const [otpVerificationError, setOtpVerificationError] = useState(null);
    const [resetTimer, setResetTimer] = useState(0);

    // Sending OTP
    const sendOTP = async () => {
        const { phone_number, email } = allFormData.pane1;

        if (phone_number === "" || email === "") {
            setOtpNotSentError("Email and Phone number added are INVALID.");
            setTimeout(() => {
                setOtpNotSentError(null);
            }, 3000);

            return;
        }

        setIsSendingOTP(true);

        try {
            const otpFormData = new FormData();

            otpFormData.append("phone_number", phone_number);
            otpFormData.append("email", email);
            const res = await axios.post(SEND_OTP_URL, otpFormData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            setOtpSentMessage(res.data["message"]);

            setTimeout(() => {
                setOtpSentMessage(null);
            }, 3000);
        } catch (e) {
            setOtpNotSentError("An Error occurred.");
            setTimeout(() => {
                setOtpNotSentError(null);
            }, 3000);
        } finally {
            setIsSendingOTP(false);
        }
    };

    // Resend otp:
    const startTimer = () => {
        setResetTimer(30);

        const interval = setInterval(() => {
            setResetTimer((prev) => {
                if (prev === 1) {
                    clearInterval(interval); // Clear interval when timer reaches 0
                }
                return prev - 1;
            });
        }, 1000);
    };

    const resendOTPWithTimer = async () => {
        await sendOTP();

        startTimer();
    };

    // Verifying the sent OTP
    const handleOTPVerification = async () => {
        const { phone_number, email } = allFormData.pane1;

        if (!otp) {
            setOtpNotSentError("A valid OTP is Required.");
            setTimeout(() => {
                setOtpNotSentError(null);
            }, 3000);

            return;
        }

        setIsVerifyingOTP(true);

        try {
            const otpVerificationFormData = new FormData();

            otpVerificationFormData.append("phone_number", phone_number);
            otpVerificationFormData.append("email", email);
            otpVerificationFormData.append("otp", otp);

            const res = await axios.post(VERIFY_OTP_URL, otpVerificationFormData, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            setOtpVerifiedMessage(res.data["message"]);
            setTimeout(() => {
                toggleTab(activeTab + 1);
                toggleTabProgress(activeTab + 1);
                setOtpVerifiedMessage(null);
            }, 2000);

            setOtpVerificationSuccessful(true);
        } catch (e) {
            setOtpVerificationError("OTP submitted is INVALID.");
            setTimeout(() => {
                setOtpVerificationError(null);
            }, 3000);
        } finally {
            setIsVerifyingOTP(false);
        }
    };

    const onBusinessTypeChange = (selectedBusinessType) => {
        setSelectedBusinessType(selectedBusinessType);

        //Find index of selected category:
        const businessTypeIndex = specifiedBusinessTypes.findIndex(
            (bType) => Object.keys(bType)[0] === `${selectedBusinessType.label}`
        );
        setSpecBusinessTypeOptions(specifiedBusinessTypes[businessTypeIndex][selectedBusinessType.label]);

        setSelectedSpecBusinessType(null);
        setAllFormData((prevState) => ({
            ...prevState,
            pane4: {
                ...prevState.pane4,
                specific_type: "",
            },
        }));

        setAllFormData((prevState) => ({
            ...prevState,
            pane4: {
                ...prevState.pane4,
                type: selectedBusinessType.value,
            },
        }));
    };

    const onSpecBusinessTypeChange = (selectedSpecBusinessType) => {
        setSelectedSpecBusinessType(selectedSpecBusinessType);

        setAllFormData((prevState) => ({
            ...prevState,
            pane4: {
                ...prevState.pane4,
                specific_type: selectedSpecBusinessType.value,
            },
        }));
    };

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                setActiveTab(tab);
            }
        }
    };

    const toggleTabProgress = (tab) => {
        if (activeTabProgress !== tab) {
            if (tab >= 1 && tab <= 4) {
                setActiveTabProgress(tab);

                if (tab === 1) {
                    setProgressValue(25);
                }
                if (tab === 2) {
                    setProgressValue(50);
                }
                if (tab === 3) {
                    setProgressValue(75);
                }
                if (tab === 4) {
                    setProgressValue(100);
                }
            }
        }
    };

    const handleFileChange = (e, pane) => {
        const file = e.target.files[0];

        // Create a new FormData object to append files
        const fileData = new FormData();
        fileData.append(e.target.name, file, file.name);

        setAllFormData((prevState) => ({
            ...prevState,
            [pane]: {
                ...prevState[pane],
                ...Object.fromEntries(fileData.entries()), // Merge with existing data
            },
        }));
    };

    const handlePasswordChange = (e) => {
        setAllFormData((prevState) => ({
            ...prevState,
            pane4: {
                ...prevState.pane4,
                password: e.target.value,
            },
        }));
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleConfirmPasswordChange = (e) => {
        setAllFormData((prevState) => ({
            ...prevState,
            pane4: {
                ...prevState.pane4,
                confirmPassword: e.target.value,
            },
        }));
    };
    const handleBlur = () => {
        const { password, confirmPassword } = allFormData.pane4;
        setPasswordMatch(password === confirmPassword);
    };

    const handleValidSubmit = async (event, values) => {
        event.preventDefault();
        // Ensure all required fields are provided before form submission:

        const anyInvalidField = validateRequiredFields(allFormData, otpVerificationSuccessful);
        setRegistrationError(anyInvalidField);
        if (anyInvalidField) {
            setTimeout(() => {
                setRegistrationError(null);
            }, 3000);

            return;
        }

        props.dispatch(registerUserStart());
        setIsSubmittingRegistration(true);

        try {
            const apiUrl = `${BACKEND_DOMAIN}/admin/merchants/`;

            const formData = new FormData();

            Object.keys(allFormData).forEach((pane) => {
                Object.keys(allFormData[pane]).forEach((field) => {
                    formData.append(field, allFormData[pane][field]);
                });
            });

            const response = await axios.post(apiUrl, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            props.dispatch(registerUserSuccessful(response.data));

            setUserRegistrationSuccessful(true);

            if (activeTab === 4) {
                setTimeout(() => {
                    navigate("/route-money/merchant/login");
                }, 2000);
            } else {
                setAllFormData((prevState) => {
                    return {
                        ...prevState,
                        [`pane${activeTab}`]: values,
                    };
                });
                toggleTab(activeTab + 1);
            }
        } catch (error) {
            // Extract the first error from the API error messages and display it for 4 seconds:
            const errorToDisplay = Object.values(error.response.data.errors)[0];

            setRegistrationFailedErrorMessage(errorToDisplay);
            setTimeout(() => {
                setRegistrationFailedErrorMessage(null);
            }, 3000);

            props.dispatch(registerUserFailed(error.message));
        } finally {
            setIsSubmittingRegistration(false);
        }
    };

    useEffect(() => {
        props.dispatch(registerUserFailed(""));
        document.body.classList.add("auth-body-bg");
    }, [props]);

    return (
        <React.Fragment>
            <div className="pt-3 pb-3">
                <Container fluid={true} className="vh-100 d-flex justify-content-center align-items-center">
                    <Row className="d-flex justify-content-center">
                        <div className="text-center">
                            <Link to="#" className="logo">
                                <img src={logodark} height="35" alt="logo" className="auth-logo logo-light mx-auto" />
                                <img src={logolight} height="35" alt="logo" className="auth-logo logo-dark mx-auto" />
                            </Link>
                            <h4 className="font-size-18 mt-4">Register account</h4>
                        </div>

                        {userRegistrationSuccessful && <Alert color="success">Registration Done Successfully.</Alert>}

                        <Col xs="12" sm="8" md="10" lg="12">
                            <Card>
                                <CardBody>
                                    <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                        <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({
                                                        active: activeTab === 1,
                                                    })}
                                                    onClick={() => {
                                                        toggleTab(1);
                                                    }}
                                                >
                                                    <span className="step-number">01</span>
                                                    <span className="step-title">Merchant Details</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({
                                                        active: activeTab === 2,
                                                    })}
                                                    onClick={() => {
                                                        toggleTab(2);
                                                    }}
                                                >
                                                    <span className="step-number">02</span>
                                                    <span className="step-title">Company Documents</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({
                                                        active: activeTab === 3,
                                                    })}
                                                    onClick={() => {
                                                        toggleTab(3);
                                                    }}
                                                >
                                                    <span className="step-number">03</span>
                                                    <span className="step-title">Verification</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({
                                                        active: activeTab === 4,
                                                    })}
                                                    onClick={() => {
                                                        toggleTab(4);
                                                    }}
                                                >
                                                    <span className="step-number">04</span>
                                                    <span className="step-title">Create Account</span>
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                        <TabContent activeTab={activeTab} className="twitter-bs-wizard-tab-content">
                                            <TabPane tabId={1}>
                                                <Form>
                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="name">
                                                                    Business Name <span className="text-danger">*</span>
                                                                </Label>
                                                                <Input
                                                                    name="name"
                                                                    value={allFormData.pane1.name}
                                                                    onChange={(e) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane1: {
                                                                                ...prevState.pane1,
                                                                                name: e.target.value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="name"
                                                                    placeholder="Enter Business Name"
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="contact_person">
                                                                    Contact Person{" "}
                                                                    <span className="text-danger">*</span>
                                                                </Label>
                                                                <Input
                                                                    name="contact_person"
                                                                    value={allFormData.pane1.contact_person}
                                                                    onChange={(e) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane1: {
                                                                                ...prevState.pane1,
                                                                                contact_person: e.target.value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="contact_person"
                                                                    placeholder="Enter Contact Person Name"
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="phone_number">
                                                                    WhatsApp Business Number{" "}
                                                                    <span className="text-danger">*</span>
                                                                </Label>
                                                                {/* <Input
                                                                    name="phone_number"
                                                                    value={allFormData.pane1.phone_number}
                                                                    onChange={(e) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane1: {
                                                                                ...prevState.pane1,
                                                                                phone_number: e.target.value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="phone_number"
                                                                    placeholder="In the format: +2547..."
                                                                /> */}
                                                                <PhoneInput
                                                                    name="phone_number"
                                                                    inputStyle={customInputStyle}
                                                                    dropdownStyle={customDropdownStyle}
                                                                    country={"ke"}
                                                                    onlyCountries={["ke", "ug", "tz", "rw"]}
                                                                    value={allFormData.pane1.phone_number}
                                                                    onChange={(value) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane1: {
                                                                                ...prevState.pane1,
                                                                                phone_number: "+" + value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    // className="form-control"
                                                                    inputClass="form-control"
                                                                    dropdownClass="form-control"
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label
                                                                    className="form-label"
                                                                    htmlFor="alt_phone_number"
                                                                >
                                                                    Contact Person Phone Number{" "}
                                                                    <span className="text-danger">*</span>
                                                                </Label>
                                                                {/* <Input
                                                                    name="alt_phone_number"
                                                                    value={allFormData.pane1.alt_phone_number}
                                                                    onChange={(e) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane1: {
                                                                                ...prevState.pane1,
                                                                                alt_phone_number: e.target.value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="alt_phone_number"
                                                                    placeholder="In the format: +2547..."
                                                                /> */}
                                                                <PhoneInput
                                                                    name="alt_phone_number"
                                                                    inputStyle={customInputStyle}
                                                                    dropdownStyle={customDropdownStyle}
                                                                    country={"ke"}
                                                                    onlyCountries={["ke", "ug", "tz", "rw"]}
                                                                    value={allFormData.pane1.alt_phone_number}
                                                                    onChange={(value) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane1: {
                                                                                ...prevState.pane1,
                                                                                alt_phone_number: "+" + value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    // className="form-control"
                                                                    inputClass="form-control"
                                                                    dropdownClass="form-control"
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="email">
                                                                    Email <span className="text-danger">*</span>
                                                                </Label>
                                                                <Input
                                                                    name="email"
                                                                    value={allFormData.pane1.email}
                                                                    onChange={(e) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane1: {
                                                                                ...prevState.pane1,
                                                                                email: e.target.value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    type="email"
                                                                    className="form-control"
                                                                    id="email"
                                                                    placeholder="Enter email"
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="location">
                                                                    Location <span className="text-danger">*</span>
                                                                </Label>
                                                                <Input
                                                                    name="location"
                                                                    value={allFormData.pane1.location}
                                                                    onChange={(e) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane1: {
                                                                                ...prevState.pane1,
                                                                                location: e.target.value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="location"
                                                                    placeholder="Enter Location"
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                                <div className="d-flex justify-content-between">
                                                    <Button
                                                        type="button"
                                                        style={{
                                                            backgroundColor: "#5664d2",
                                                        }}
                                                        onClick={() => {
                                                            toggleTab(activeTab - 1);
                                                            toggleTabProgress(activeTab - 1);
                                                        }}
                                                    >
                                                        Previous
                                                    </Button>

                                                    <Button
                                                        type="button"
                                                        style={{
                                                            backgroundColor: "#5664d2",
                                                        }}
                                                        onClick={() => {
                                                            toggleTab(activeTab + 1);
                                                            toggleTabProgress(activeTab + 1);
                                                        }}
                                                    >
                                                        Next
                                                    </Button>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId={2}>
                                                <Form encType="multipart/form-data">
                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="kra_pin">
                                                                    KRA Pin <span className="text-danger">*</span>
                                                                </Label>
                                                                <Input
                                                                    name="kra_pin"
                                                                    value={allFormData.pane2.kra_pin}
                                                                    onChange={(e) => {
                                                                        setAllFormData((prevState) => ({
                                                                            ...prevState,
                                                                            pane2: {
                                                                                ...prevState.pane2,
                                                                                kra_pin: e.target.value,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="kra_pin"
                                                                    placeholder="Enter KRA Pin"
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="image">
                                                                    Tax Compliance (optional)
                                                                </Label>
                                                                <Input
                                                                    name="image"
                                                                    onChange={(e) => handleFileChange(e, "pane2")}
                                                                    //validate={{ required: true }}
                                                                    type="file"
                                                                    className="form-control"
                                                                    id="image"
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="profile_image">
                                                                    Profile Photo <span className="text-danger">*</span>
                                                                </Label>
                                                                <Input
                                                                    name="profile_image"
                                                                    onChange={(e) => handleFileChange(e, "pane2")}
                                                                    validate={{
                                                                        required: true,
                                                                    }}
                                                                    type="file"
                                                                    className="form-control"
                                                                    id="profile_image"
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="cover_image">
                                                                    Background Image{" "}
                                                                    <span className="text-danger">*</span>
                                                                </Label>
                                                                <Input
                                                                    name="cover_image"
                                                                    onChange={(e) => handleFileChange(e, "pane2")}
                                                                    validate={{
                                                                        required: true,
                                                                    }}
                                                                    type="file"
                                                                    className="form-control"
                                                                    id="cover_image"
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                                <div className="d-flex justify-content-between">
                                                    <Button
                                                        type="button"
                                                        style={{
                                                            backgroundColor: "#5664d2",
                                                        }}
                                                        onClick={() => {
                                                            toggleTab(activeTab - 1);
                                                            toggleTabProgress(activeTab - 1);
                                                        }}
                                                    >
                                                        Previous
                                                    </Button>

                                                    <Button
                                                        type="button"
                                                        style={{
                                                            backgroundColor: "#5664d2",
                                                        }}
                                                        onClick={() => {
                                                            toggleTab(activeTab + 1);
                                                            toggleTabProgress(activeTab + 1);
                                                        }}
                                                    >
                                                        Next
                                                    </Button>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId={3}>
                                                <div>
                                                    <Form>
                                                        <Row>
                                                            <Col lg="10">
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="otp">
                                                                        OTP Verification{" "}
                                                                        <span className="text-danger">*</span>
                                                                    </Label>
                                                                    {otpSentMessage && (
                                                                        <Alert color="success">{otpSentMessage}</Alert>
                                                                    )}
                                                                    {otpNotSentError && (
                                                                        <Alert color="danger">{otpNotSentError}</Alert>
                                                                    )}
                                                                    {otpVerificationError && (
                                                                        <Alert color="danger">
                                                                            {otpVerificationError}
                                                                        </Alert>
                                                                    )}
                                                                    <div className="d-flex align-items-center mb-2">
                                                                        <Input
                                                                            name="otp"
                                                                            value={otp}
                                                                            onChange={(e) => setOtp(e.target.value)}
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="otp"
                                                                            placeholder="Enter OTP"
                                                                            style={{ flex: 1 }}
                                                                        />
                                                                        <Button
                                                                            type="button"
                                                                            color="primary"
                                                                            onClick={resendOTPWithTimer}
                                                                            className="ms-2"
                                                                            disabled={isSendingOTP || resetTimer > 0}
                                                                        >
                                                                            {isSendingOTP ? (
                                                                                <>
                                                                                    <span
                                                                                        className="spinner-border spinner-border-sm"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                    ></span>
                                                                                    <span className="ms-2">
                                                                                        Sending..
                                                                                    </span>
                                                                                </>
                                                                            ) : resetTimer > 0 ? (
                                                                                `Resend in: ${resetTimer}s`
                                                                            ) : (
                                                                                "Send OTP"
                                                                            )}
                                                                        </Button>
                                                                        <Button
                                                                            type="button"
                                                                            color="success"
                                                                            onClick={handleOTPVerification}
                                                                            className="ms-2"
                                                                            disabled={isVerifyingOTP}
                                                                        >
                                                                            {isVerifyingOTP ? (
                                                                                <>
                                                                                    <span
                                                                                        className="spinner-border spinner-border-sm"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                    ></span>
                                                                                    <span className="ms-2">
                                                                                        Verifying..
                                                                                    </span>
                                                                                </>
                                                                            ) : (
                                                                                "Verify OTP"
                                                                            )}
                                                                        </Button>
                                                                    </div>
                                                                    {otpVerifiedMessage && (
                                                                        <Alert color="success">
                                                                            {otpVerifiedMessage}
                                                                        </Alert>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                    <div className="d-flex justify-content-between">
                                                        <Button
                                                            type="button"
                                                            style={{
                                                                backgroundColor: "#5664d2",
                                                            }}
                                                            onClick={() => {
                                                                toggleTab(activeTab - 1);
                                                                toggleTabProgress(activeTab - 1);
                                                            }}
                                                        >
                                                            Previous
                                                        </Button>

                                                        <Button
                                                            type="button"
                                                            style={{
                                                                backgroundColor: "#5664d2",
                                                            }}
                                                            onClick={() => {
                                                                toggleTab(activeTab + 1);
                                                                toggleTabProgress(activeTab + 1);
                                                            }}
                                                        >
                                                            Next
                                                        </Button>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId={4}>
                                                <div>
                                                    <AvForm
                                                        onValidSubmit={handleValidSubmit}
                                                        encType="multipart/form-data"
                                                    >
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label>
                                                                        General Business Type{" "}
                                                                        <span className="text-danger">*</span>
                                                                    </Label>

                                                                    <Select
                                                                        placeholder="Select or Type to filter..."
                                                                        options={businessTypes}
                                                                        value={selectedBusinessType}
                                                                        onChange={onBusinessTypeChange}
                                                                        className="form-control"
                                                                        styles={colourStyles}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label>
                                                                        Specific Business Type{" "}
                                                                        <span className="text-danger">*</span>
                                                                    </Label>

                                                                    <Select
                                                                        placeholder="Select or Type to filter..."
                                                                        options={
                                                                            !selectedBusinessType
                                                                                ? [
                                                                                      {
                                                                                          value: "",
                                                                                          label: "Select General Type First",
                                                                                      },
                                                                                  ]
                                                                                : specBusinessTypeOptions
                                                                        }
                                                                        value={selectedSpecBusinessType}
                                                                        onChange={onSpecBusinessTypeChange}
                                                                        className="form-control"
                                                                        styles={colourStyles}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-1">
                                                                    <Label className="form-label" htmlFor="password">
                                                                        Password <span className="text-danger">*</span>
                                                                    </Label>
                                                                    <Input
                                                                        name="password"
                                                                        value={allFormData.pane4.password}
                                                                        onChange={handlePasswordChange}
                                                                        onBlur={handleBlur}
                                                                        validate={{
                                                                            required: true,
                                                                        }}
                                                                        type={passwordVisible ? "text" : "password"}
                                                                        className="form-control"
                                                                        id="password"
                                                                        placeholder="Enter password"
                                                                    />
                                                                    <div className="d-flex justify-content-end mt-1">
                                                                        <small
                                                                            className="text-muted cursor-pointer"
                                                                            onClick={togglePasswordVisibility}
                                                                        >
                                                                            {passwordVisible ? "Hide" : "Show"}
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg="6">
                                                                <div className="mb-3 d-flex flex-column">
                                                                    <Label
                                                                        className="form-label"
                                                                        htmlFor="confirmPassword"
                                                                    >
                                                                        Confirm Password{" "}
                                                                        <span className="text-danger">*</span>
                                                                    </Label>
                                                                    <Input
                                                                        name="confirmPassword"
                                                                        value={allFormData.pane4.confirmPassword}
                                                                        onChange={handleConfirmPasswordChange}
                                                                        onBlur={handleBlur}
                                                                        type={passwordVisible ? "text" : "password"}
                                                                        className={`form-control ${
                                                                            passwordMatch ? "" : "is-invalid"
                                                                        }`}
                                                                        id="confirmPassword"
                                                                        placeholder="Confirm password"
                                                                    />

                                                                    {!passwordMatch && (
                                                                        <div className="invalid-feedback">
                                                                            Passwords do not match
                                                                        </div>
                                                                    )}
                                                                    <small
                                                                        style={{
                                                                            marginTop: !passwordMatch ? "-18px" : 3,
                                                                        }}
                                                                        className="text-muted cursor-pointer align-self-end"
                                                                        onClick={togglePasswordVisibility}
                                                                    >
                                                                        {passwordVisible ? "Hide" : "Show"}
                                                                    </small>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        {registrationError && (
                                                            <Alert color="danger">{registrationError}</Alert>
                                                        )}
                                                        {registrationFailedErrorMessage && (
                                                            <Alert color="danger">
                                                                {registrationFailedErrorMessage}
                                                            </Alert>
                                                        )}
                                                        <div className="d-flex justify-content-between">
                                                            <Button
                                                                type="button"
                                                                style={{
                                                                    backgroundColor: "#5664d2",
                                                                }}
                                                                disabled={activeTab === 1}
                                                                onClick={() => {
                                                                    toggleTab(activeTab - 1);
                                                                    toggleTabProgress(activeTab - 1);
                                                                }}
                                                            >
                                                                Previous
                                                            </Button>

                                                            <Button
                                                                type="submit"
                                                                style={{
                                                                    backgroundColor: "#5664d2",
                                                                }}
                                                                disabled={isSubmittingRegistration}
                                                            >
                                                                {isSubmittingRegistration ? (
                                                                    <>
                                                                        <span
                                                                            className="spinner-border spinner-border-sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        ></span>
                                                                        <span className="ms-2">Creating User..</span>
                                                                    </>
                                                                ) : (
                                                                    "Register"
                                                                )}
                                                            </Button>
                                                        </div>
                                                    </AvForm>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-4 text-center">
                                <p className="mb-0">
                                    By registering you agree to the Route Money{" "}
                                    <Link to="#" className="text-primary">
                                        {" "}
                                        Terms of Use
                                    </Link>
                                </p>
                                <p>
                                    Already have an account ?{" "}
                                    <Link to="/route-money/merchant/login" className="fw-medium text-primary">
                                        {" "}
                                        Login
                                    </Link>{" "}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const { user, registrationError, loading } = state.Account;
    return { user, registrationError, loading };
};

export default connect(mapStateToProps)(Register);
