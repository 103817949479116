import axios from "axios";
import BACKEND_DOMAIN from "../../../service";

// API endpoints
const USERS_LIST_URL = `${BACKEND_DOMAIN}/admin/users/`;

/* This function fetches all users in the platform, and returns an object with the different types of users in array form. */
export const fetchStructuredUsers = async (token) => {
    const admins = [];
    const merchants = [];
    const consumers = [];

    let platformUsers;

    try {
        const response = await axios.get(USERS_LIST_URL, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        const all_users = response.data.results;

        for (let user of all_users) {
            if (user.is_superuser && !user.is_business_partner) {
                admins.push(user);
            } else if (!user.is_superuser && user.is_business_partner) {
                merchants.push(user);
            } else if (!user.is_superuser && !user.is_business_partner) {
                consumers.push(user);
            }
        }

        platformUsers = {
            Admins: admins,
            Merchants: merchants,
            Consumers: consumers,
        };

        return platformUsers;
    } catch (e) {
        console.error("Error fetching admin users. ", e);
    }
};
