// action.js
import {
    REGISTER_USER,
    REGISTER_USER_SUCCESSFUL,
    REGISTER_USER_FAILED,
} from './actionTypes';

// Action Creators
export const registerUserStart = () => {
    return {
        type: REGISTER_USER,
    };
};

export const registerUserSuccessful = (user) => {
    return {
        type: REGISTER_USER_SUCCESSFUL,
        payload: user,
    };
};

export const registerUserFailed = (error) => {
    return {
        type: REGISTER_USER_FAILED,
        payload: error,
    };
};

