import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, Label, Button, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { uploadBranchRequest, uploadBranchSuccess, uploadBranchFailure } from "../../thunks";
import Select from "react-select";
import walletTypes from "../../utilities/walletTypes";
import BACKEND_DOMAIN from "../../../../service";

const MerchantBranchAdd = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [branch, setBranch] = useState({
        sub_name: "",
        sub_contact_person: "",
        sub_phone_number: "",
        sub_alt_phone_number: "",
        sub_location: "",
        sub_email: "",
        sub_kra_pin: "",
        sub_profile_image: "",
        sub_cover_image: "",
        sub_password: "",
        sub_wallet_balance: "",
    });

    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedWalletType, setSelectedWalletType] = useState(null);
    const [creatingError, setCreatingError] = useState(null);

    const token = useSelector((state) => state.Login.token);

    const onWalletTypeChange = (selectedWalletType) => {
        setSelectedWalletType(selectedWalletType);

        setBranch((prevBranch) => ({
            ...prevBranch,
            sub_wallet_balance: selectedWalletType.value,
        }));
    };

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;

        // If the input is a file, get the file object
        const file = type === "file" ? e.target.files[0] : null;

        setBranch((prevBranch) => ({
            ...prevBranch,
            [name]: type === "file" ? file : value,
        }));
    };

    // Personalized validation function for the wallet type:
    const formValidator = () => {
        if (!selectedWalletType) {
            setCreatingError("Please select a wallet type.");
            setTimeout(() => {
                setCreatingError(null);
            }, 3000);
            return false;
        }

        return true;
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        if (!formValidator()) return;

        setIsLoading(true);

        try {
            dispatch(uploadBranchRequest());
            //const selectedCategory = categories.find((category) => category.name === product.category_id);
            //console.log(selectedCategory.id);

            const formData = new FormData();
            formData.append("sub_name", branch.sub_name);
            formData.append("sub_profile_image", branch.sub_profile_image);
            formData.append("sub_cover_image", branch.sub_cover_image);
            formData.append("sub_contact_person", branch.sub_contact_person);
            formData.append("sub_phone_number", branch.sub_phone_number);
            formData.append("sub_alt_phone_number", branch.sub_alt_phone_number);
            formData.append("sub_email", branch.sub_email);
            formData.append("sub_location", branch.sub_location);
            formData.append("sub_kra_pin", branch.sub_kra_pin);
            formData.append("sub_password", branch.sub_password);
            formData.append("sub_wallet_balance", branch.sub_wallet_balance);

            const apiurl = `${BACKEND_DOMAIN}/business/merchant/branch/create`;
            const response = await fetch(apiurl, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Error adding branch");
            }

            const data = await response.json();
            dispatch(uploadBranchSuccess(data));

            // Show a success message:
            setSuccessMessage("Branch has been created successfully!");

            setTimeout(() => {
                navigate("/route-money/merchant/branch/list");
            }, 2000);

            setTimeout(() => {
                setSuccessMessage("");
            }, 5000);
        } catch (error) {
            dispatch(uploadBranchFailure(error.message));
        }

        setIsLoading(false);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Add Branch"
                        breadcrumbItems={[
                            { title: "Branches", link: "/branch" },
                            { title: "Add Branch", link: "#" },
                        ]}
                    />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Add Branch</h4>
                                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                                    <AvForm onValidSubmit={handleFormSubmit} enctype="multipart/form-data">
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subName">
                                                        Branch Name
                                                    </Label>
                                                    <AvField
                                                        name="sub_name"
                                                        id="subName"
                                                        type="text"
                                                        placeholder="Enter Branch Name"
                                                        errorMessage="Enter Branch Name"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subLocation">
                                                        Location
                                                    </Label>
                                                    <AvField
                                                        name="sub_location"
                                                        id="subLocation"
                                                        type="text"
                                                        placeholder="Enter Location"
                                                        errorMessage="Enter Location"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subContactPerson">
                                                        Contact Person
                                                    </Label>
                                                    <AvField
                                                        name="sub_contact_person"
                                                        id="subContactPerson"
                                                        type="text"
                                                        placeholder="Enter Contact Person"
                                                        errorMessage="Enter Contact person"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subKraPin">
                                                        KRA PIN
                                                    </Label>
                                                    <AvField
                                                        name="sub_kra_pin"
                                                        id="subKraPin"
                                                        type="text"
                                                        placeholder="Enter KRA PIN"
                                                        errorMessage="Enter KRA PIN"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label>Select Wallet Type</Label>
                                                    <Select
                                                        placeholder="Select or Type to filter..."
                                                        options={walletTypes}
                                                        value={selectedWalletType}
                                                        onChange={onWalletTypeChange}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subProfileImage">
                                                        Branch Profile Image
                                                    </Label>
                                                    <AvField
                                                        name="sub_profile_image"
                                                        id="subProfileImage"
                                                        type="file"
                                                        errorMessage="Select Profile  Image"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subCoverImage">
                                                        Branch Cover Image
                                                    </Label>
                                                    <AvField
                                                        name="sub_cover_image"
                                                        id="subCoverImage"
                                                        type="file"
                                                        errorMessage="Select Cover Image"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="phoneNumber">
                                                        Phone Number
                                                    </Label>
                                                    <AvField
                                                        name="sub_phone_number"
                                                        id="phoneNumber"
                                                        type="text"
                                                        placeholder="Enter Phone Number"
                                                        errorMessage="Enter Phone Number"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="altPhoneNumber">
                                                        Alternative Phone Number
                                                    </Label>
                                                    <AvField
                                                        name="sub_alt_phone_number"
                                                        id="altPhoneNumber"
                                                        type="text"
                                                        placeholder="Enter Alternative Phone Number"
                                                        errorMessage="Enter Alternative Phone Number"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subEmail">
                                                        Email
                                                    </Label>
                                                    <AvField
                                                        name="sub_email" // Updated to match the API field name
                                                        id="subEmail"
                                                        type="email"
                                                        placeholder="Enter Email"
                                                        errorMessage="Enter Email"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subPassword">
                                                        Password
                                                    </Label>
                                                    <AvField
                                                        name="sub_password"
                                                        id="subPassword"
                                                        type="password"
                                                        placeholder="Enter Password"
                                                        errorMessage="Enter Password"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        {creatingError && <Alert color="danger">{creatingError}</Alert>}
                                        <Button color="primary" type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <>
                                                    <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                    <span className="ms-2">Adding..</span>
                                                </>
                                            ) : (
                                                "Add Branch"
                                            )}
                                        </Button>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MerchantBranchAdd;
