import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
// import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import {
    changeLayout,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    changePreloader,
} from "../../store/actions";
import withRouter from "../Common/withRouter";

class SidebarContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pathName: this.props.router.location.pathname,
            localIsBusinessPartner: this.props.isBusinessPartner,
        };
    }

    componentDidMount() {
        this.initMenu();
        this.fetchAndUpdateBusinessPartner();
    }

    UNSAFE_componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.type !== prevProps.type) {
                this.initMenu();
            }
        }
        if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
            this.setState({ pathName: this.props.router.location.pathname }, () => {
                this.initMenu();
            });
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }

    initMenu() {
        const { pathName } = this.state;

        const menu = new MetisMenu("#side-menu");

        // Adding and removing the "mm-active" class to classname on clicking sidebar items.
        menu.off("click");

        menu.on("click", (event) => {
            const target = event.target;
            if (target.tagName === "A") {
                // Remove mm-active class from all links
                const links = document.querySelectorAll("#side-menu a");
                links.forEach((link) => {
                    link.classList.remove("mm-active");
                });

                // Add mm-active class to the clicked link
                target.classList.add("mm-active");

                // Activate parent dropdowns
                this.activateParentDropdown(target);
            }
        });

        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (pathName === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        } else {
            // If there is no matching menu item (e.g., when clicking a link without nested ul),
            // still activate the clicked link
            const currentLink = document.querySelector("#side-menu a.mm-active");
            if (currentLink) {
                this.activateParentDropdown(currentLink);
            }
        }
    }

    activateParentDropdown = (item) => {
        // Remove mm-active class from all relevant elements
        const links = document.querySelectorAll("#side-menu a");
        links.forEach((link) => {
            if (link !== item) {
                link.classList.remove("mm-active");
            }
        });

        item.classList.add("mm-active");

        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); // a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
        }
    };

    fetchAndUpdateBusinessPartner = () => {
        const localData = localStorage.getItem("authUser");

        try {
            const dataString = JSON.parse(localData);
            this.setState({ localIsBusinessPartner: dataString.is_business_partner });
        } catch (e) {
            console.error("Error while fetching and updating local BusinessPartner", e);
        }
    };

    render() {
        const { isAuthenticated } = this.props;
        const { localIsBusinessPartner } = this.state;
        return (
            <React.Fragment>
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">{this.props.t("Menu")}</li>
                        {isAuthenticated && !localIsBusinessPartner && (
                            <React.Fragment>
                                <li>
                                    <Link to="/route-money/dashboard" className="waves-effect mm-active">
                                        <i className="ri-dashboard-line"></i>
                                        <span className="badge rounded-pill bg-success float-end"></span>
                                        <span className="ms-1">{this.props.t("Dashboard")}</span>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/#" className="has-arrow waves-effect">
                                        <i className="ri-wallet-3-line"></i>
                                        <span className="ms-1">{this.props.t("Transactions")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/route-money/collection-transactions">
                                                {this.props.t("Collections")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/route-money/intrawallet-transactions">
                                                {this.props.t("Intrawallet")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/route-money/disbursement-history">
                                                {this.props.t("Disbursement")}
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/#" className="has-arrow waves-effect">
                                        <i className="ri-shopping-cart-2-line"></i>
                                        <span className="ms-1">{this.props.t("Orders")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/route-money/pending-orders">
                                                {this.props.t("Pending Orders")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/route-money/order-history">{this.props.t("Order History")}</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/#" className="has-arrow waves-effect">
                                        <i className="ri-coupon-2-line"></i>
                                        <span className="ms-1">{this.props.t("Coupon Management")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/route-money/coupon-balance">
                                                {this.props.t("View Customer Coupon Balances")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/route-money/coupon-history">
                                                {this.props.t("History of Redeemed Coupons")}
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <Link to="/route-money/revenue-report" className="waves-effect">
                                        <i className="ri-bar-chart-line"></i>
                                        <span className="ms-1">{this.props.t("Revenue Reports")}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/#" className="has-arrow waves-effect">
                                        <i className="ri-user-line"></i>
                                        <span className="ms-1">{this.props.t("Users")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/route-money/pending-pos">
                                                {this.props.t("Pending POS Approvals")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/route-money/customers">{this.props.t("Customers")}</Link>
                                        </li>
                                        <li>
                                            <Link to="/route-money/merchants">{this.props.t("Merchants")}</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/#" className="has-arrow waves-effect">
                                        <i className="ri-group-line"></i>
                                        <span className="ms-1">{this.props.t("User Management")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/route-money/create-user">
                                                {this.props.t("Create User Role")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/route-money/user">{this.props.t("View and Manage Users")}</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/route-money/activities" className="waves-effect">
                                        <i className="ri-git-branch-line"></i>
                                        <span className="badge rounded-pill bg-success float-end"></span>
                                        <span className="ms-1">{this.props.t("Activity Logs")}</span>
                                    </Link>
                                </li>
                            </React.Fragment>
                        )}
                        {isAuthenticated && localIsBusinessPartner && (
                            <React.Fragment>
                                <li>
                                    <Link to="/route-money/dashboard" className="waves-effect mm-active">
                                        <i className="ri-dashboard-line"></i>
                                        <span className="badge rounded-pill bg-success float-end"></span>
                                        <span className="ms-1">{this.props.t("Dashboard")}</span>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/#" className="has-arrow waves-effect">
                                        <i className="ri-store-line"></i>
                                        <span className="ms-1">{this.props.t("Store")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/#" className="has-arrow waves-effect">
                                                <i className="ri-shopping-cart-2-line"></i>
                                                <span className="ms-1">{this.props.t("Orders")}</span>
                                            </Link>
                                            <ul className="sub-menu">
                                                <li>
                                                    <Link to="/route-money/merchant/pending-orders">
                                                        {this.props.t("Pending Orders")}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/route-money/merchant/order-history">
                                                        {this.props.t("Order History")}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link to="/#" className="has-arrow waves-effect">
                                                <i className="ri-shopping-bag-2-line"></i>
                                                <span className="ms-1">{this.props.t("Products")}</span>
                                            </Link>
                                            <ul className="sub-menu">
                                                <li>
                                                    <Link to="/route-money/merchant/product/categories">
                                                        {this.props.t("Categories")}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/route-money/merchant/product/sub-categories">
                                                        {this.props.t("Sub-Categories")}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/route-money/merchant/products">
                                                        {this.props.t("Products")}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li>
                                            <Link to="/#" className="has-arrow waves-effect">
                                                <i className="ri-coupon-line"></i>
                                                <span className="ms-1">{this.props.t("Offers")}</span>
                                            </Link>
                                            <ul className="sub-menu">
                                                <li>
                                                    <Link to="/route-money/merchant/product-offers">
                                                        {this.props.t("Product Discount")}
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/route-money/merchant/cashback-offers">
                                                        {this.props.t("Cashback Offers")}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    <Link to="/route-money/merchant/transactions" className="waves-effect">
                                        <i className="ri-wallet-3-line"></i>
                                        <span className="ms-1">{this.props.t("Transactions")}</span>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/route-money/merchant/branch/list" className="waves-effect">
                                        <i className="ri-page-separator"></i>
                                        <span className="ms-1">{this.props.t("Branches")}</span>
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/#" className="has-arrow waves-effect">
                                        <i className="ri-bar-chart-line"></i>
                                        <span className="ms-1">{this.props.t("My Reports")}</span>
                                    </Link>
                                    <ul className="sub-menu">
                                        <li>
                                            <Link to="/route-money/merchant/revenue-report">
                                                {this.props.t("Revenue Report")}
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </React.Fragment>
                        )}
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = (state) => {
    return {
        ...state.Layout,
        isAuthenticated: state.Login.isAuthenticated,
        isBusinessPartner: state.Login.isBusinessPartner,
    };
};

export default withRouter(
    connect(mapStatetoProps, {
        changeLayout,
        changeSidebarTheme,
        changeSidebarType,
        changeLayoutWidth,
        changePreloader,
    })(withTranslation()(SidebarContent))
);
