import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Button } from "reactstrap";
import classnames from "classnames";
import EditProfileForm from "./edit";
import axios from "axios";
import CopyLink from "../../../components/CommonForBoth/TopbarDropdown/CopyLink";
import BACKEND_DOMAIN, { STORE_FRONT_DOMAIN } from "../../../service";

const WEB_APP_URL = STORE_FRONT_DOMAIN;

function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const ProfilePage = () => {
    const [userData, setUserData] = useState({});
    const authToken = useSelector((state) => state.Login.token);
    const [isEditing, setIsEditing] = useState(false);
    const [activeTab, setActiveTab] = useState(1);

    // let userName = "";

    // Define the fetchUserData function
    const fetchUserData = async () => {
        try {
            const apiUrl = `${BACKEND_DOMAIN}/business/merchant/detail/`;
            const response = await axios.get(apiUrl, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            // Set the fetched user data in the state
            setUserData(response.data);

            // userName = capitalizeFirstLetter(userData.name);
        } catch (error) {
            console.error("Error fetching user details:", error.message);
        }
    };

    // Use useEffect to fetch user details from the API when the component mounts
    useEffect(() => {
        fetchUserData();
    }, [authToken]); // The dependency array ensures that the effect runs only when authToken changes

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 2) {
                setActiveTab(tab);
            }
        }
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };

    const handleSaveEdit = async (updatedData) => {
        try {
            const formData = new FormData();
            formData.append("profile_image", updatedData.profile_image);

            const apiUrl = `${BACKEND_DOMAIN}/business/merchant/image/update`;
            await axios.put(apiUrl, formData, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            setIsEditing(false);
            // Fetch updated user data and update state if needed
            fetchUserData();
        } catch (error) {
            console.error("Error updating profile:", error.message);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className="justify-content-center mb-5 mb-sm-0 ">
                        <Col md="12" className="h-100">
                            <Card>
                                <CardBody>
                                    <div className="d-flex flex-column flex-md-row">
                                        <div sm={12} md={3} className="text-center me-md-3">
                                            <div className="mb-3">
                                                <img
                                                    src={userData.cover_image}
                                                    alt="Cover Image"
                                                    style={{
                                                        objectFit: "cover",
                                                        borderRadius: 8,
                                                        height: "150px",
                                                        width: "100%",
                                                    }}
                                                />
                                            </div>

                                            <div>
                                                <img
                                                    src={userData.profile_image}
                                                    alt="Profile"
                                                    className=" avatar-lg"
                                                    style={{ objectFit: "cover", borderRadius: 8 }}
                                                />
                                                {/* <div className="edit-icon mt-2" onClick={handleEditClick}>
                                                    <i className="fas fa-pencil-alt"></i>
                                                </div> */}

                                                <h5 className="mt-2">
                                                    <b>{userData.name && capitalizeFirstLetter(userData.name)}</b>
                                                </h5>

                                                <CopyLink url={WEB_APP_URL} slugName={userData?.slug} />

                                                {isEditing && (
                                                    <EditProfileForm
                                                        onCancel={handleCancelEdit}
                                                        onSave={handleSaveEdit}
                                                        userData={userData}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            sm={12}
                                            md={9}
                                            className="mt-xs-3 d-flex flex-column"
                                            style={{ width: "100%" }}
                                        >
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 1 })}
                                                        onClick={() => {
                                                            toggleTab(1);
                                                        }}
                                                    >
                                                        Business Info
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === 2 })}
                                                        onClick={() => {
                                                            toggleTab(2);
                                                        }}
                                                    >
                                                        Logisitics
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId={1}>
                                                    <Row>
                                                        <Col
                                                            sm="12"
                                                            className="d-flex flex-column align-items-start p-4"
                                                        >
                                                            <h6 style={{ fontSize: "13px" }}>Business Type</h6>
                                                            <p>
                                                                <b>{userData.type}</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>Email</h6>
                                                            <p>
                                                                <b>{userData.email}</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>KRA PIN</h6>
                                                            <p>
                                                                <b>{userData.kra_pin}</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>
                                                                WhatsApp Business Phone No
                                                            </h6>
                                                            <p>
                                                                <b>{userData.phone_number}</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>
                                                                Contact Person Phone No
                                                            </h6>
                                                            <p>
                                                                <b>{userData.alt_phone_number}</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>Contact Person</h6>
                                                            <p>
                                                                <b>{userData.contact_person}</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>Location</h6>
                                                            <p>
                                                                <b>{userData.location}</b>
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId={2}>
                                                    <Row>
                                                        <Col sm="12" className="p-4">
                                                            <h6 style={{ fontSize: "13px" }}>VAT:</h6>
                                                            <p>
                                                                <b>16%</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>
                                                                Delivery Fees Within Nairobi:
                                                            </h6>
                                                            <p>
                                                                <b>KES 700</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>Opening Hours:</h6>
                                                            <p>
                                                                <b>Monday - Friday: 08:00AM</b>
                                                            </p>
                                                            <p>
                                                                <b>Saturday & Sunday: 08:00AM</b>
                                                            </p>
                                                            <h6 style={{ fontSize: "13px" }}>Closing Hours:</h6>
                                                            <p>
                                                                <b>Monday - Friday: 9:00PM</b>
                                                            </p>
                                                            <p>
                                                                <b>Saturday & Sunday: 8:00PM</b>
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            </TabContent>
                                            <div xs={12} lg={12} className="d-flex justify-content-end">
                                                <Button
                                                    style={{
                                                        backgroundColor: "#5664d2",
                                                        alignSelf: "end",
                                                    }}
                                                    onClick={handleEditClick}
                                                >
                                                    Update Profile Details
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ProfilePage;
