// EditProfileForm.js
import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Label } from "reactstrap";

const EditProfileForm = ({ onCancel, onSave, userData }) => {
    const [updatedData, setUpdatedData] = useState({
        profile_image: null, // This will store the selected file
        // Add other fields as needed
    });

    const [isLoading, setIsLoading] = useState(false);

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        setUpdatedData((prevData) => ({
            ...prevData,
            profile_image: file,
        }));
    };

    const handleSave = () => {
        setIsLoading(true);
        // Validate data or perform any other checks before saving

        // Pass the updatedData to the onSave callback
        onSave(updatedData);

        setIsLoading(false);
    };

    return (
        <Modal isOpen={true} role="document" autoFocus={true} centered={true} className="composemodal" tabIndex="-1">
            <div className="modal-content">
                <ModalHeader>Edit Profile</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="profile_image_url">Profile Photo</Label>
                        <input
                            type="file"
                            className="form-control"
                            id="profile_image"
                            name="profile_image"
                            onChange={handleFileChange}
                        />
                    </FormGroup>
                    {/* Add more fields for other user data as needed */}
                </ModalBody>
                <div className="modal-footer">
                    <Button color="secondary" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button color="primary" type="submit" onClick={handleSave} disabled={isLoading}>
                        {isLoading ? (
                            <>
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                <span className="ms-2">Updating..</span>
                            </>
                        ) : (
                            "Save Changes"
                        )}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default EditProfileForm;
