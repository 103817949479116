import { REGISTER_USER, REGISTER_USER_SUCCESSFUL, REGISTER_USER_FAILED } from "./actionTypes";

const initialState = {
    user: null,
    registrationError: null,
    loading: null,
    isBusinessPartner: true,
};

const Account = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER_USER:
            state = {
                ...state,
                user: null,
                loading: true,
                registrationError: null,
            };
            break;

        case REGISTER_USER_SUCCESSFUL:
            state = {
                ...state,
                user: action.payload,
                loading: false,
                registrationError: null,
            };
            break;

        case REGISTER_USER_FAILED:
            state = {
                ...state,
                loading: false,
                registrationError: action.payload,
            };
            break;

        default:
            // No need to modify state in the default case
            break;
    }
    return state;
};

export default Account;
