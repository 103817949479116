import React from "react";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { Container, Row } from "reactstrap";
import MiniWidgets from "../../../Dashboard/MiniWidgets";

const RevenueReport = () => {
    // Define reports for Admins:
    const superUserReports = [
        {
            icon: "ri-stack-line",
            title: "Transactions Volume",
            value: "KES 0",
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "#",
        },
        {
            icon: "ri-shopping-cart-2-line",
            title: "Total Orders",
            value: "0",
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "",
        },
        {
            icon: "ri-user-line",
            title: "Total Cashback Value",
            value: "KES 0",
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "",
        },
    ];

    const breadcrumbItems = [
        { title: "Reports", link: "/" },
        { title: "Revenue Reports", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Revenue Reports" breadcrumbItems={breadcrumbItems} />
                    <Row className="pb-5">
                        <MiniWidgets reports={superUserReports} />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default RevenueReport;
