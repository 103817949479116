// auth/login/reducer.js

import {
    CHECK_LOGIN,
    LOGIN_USER_SUCCESSFUL,
    API_ERROR,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    SET_AUTH_TOKEN,
} from "./actionTypes";
const storedToken = localStorage.getItem("authToken");
const initialState = {
    isAuthenticated: !!storedToken,
    isBusinessPartner: !!storedToken,
    loginError: null,
    message: null,
    loading: false,
    token: storedToken || null,
    username: null,
};

const Login = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_LOGIN:
            return {
                ...state,
                loading: true,
            };

        case LOGIN_USER_SUCCESSFUL:
            const { token } = action.payload;
            // Store the token in localStorage
            localStorage.setItem("authToken", token);
            return {
                ...state,
                loading: false,
                isAuthenticated: true, // Set isAuthenticated to true on successful login
                isBusinessPartner: action.payload.is_business_partner,
                token: action.payload.token,
                username: action.payload.username,
            };

        case LOGOUT_USER:
            localStorage.removeItem("authToken");
            return {
                ...state,
                isAuthenticated: false, // Set isAuthenticated to false on logout
                isBusinessPartner: true,
                token: null,
            };

        case LOGOUT_USER_SUCCESS:
            return {
                ...state,
            };

        case API_ERROR:
            return {
                ...state,
                loading: false,
                loginError: action.payload,
            };
        case SET_AUTH_TOKEN:
            //console.log('Token HERE:', action.payload);
            return {
                ...state,
                token: action.payload,
            };

        default:
            return state;
    }
};

export default Login;
