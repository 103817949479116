// import React from "react";
// import { Link } from "react-router-dom";
// import { Row, Col, Container } from "reactstrap";

// const Footer = () => {
//     return (
//         <React.Fragment>
//             <footer className="footer">
//                 <Container fluid>
//                     <Link to="/route-money/dashboard" className="me-2">
//                         <span>
//                             <i className="ri-shield-line align-middle me-1"></i>Privacy Policy
//                         </span>
//                     </Link>
//                     <Link to="/route-money/dashboard" className="">
//                         <span>
//                             <i className="ri-article-fill align-middle me-1"></i>Terms of Service
//                         </span>
//                     </Link>

//                     <span sm={12}>{new Date().getFullYear()} © Route Money.</span>
//                 </Container>
//             </footer>
//         </React.Fragment>
//     );
// };

// export default Footer;

import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import "./footer.css";

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer h-auto">
                <Container fluid>
                    <Row className="align-items-center text-center text-md-start">
                        {/* Links for Privacy Policy and Terms of Service */}
                        <Col xs={12} md={6} className="mb-3 mb-md-0">
                            <div className="d-flex justify-content-center justify-content-md-start">
                                <Link to="/route-money/dashboard" className="me-2 custom-link">
                                    <span className="d-flex align-items-center me-3" style={{ fontsize: "10px" }}>
                                        <i className="ri-shield-line align-middle me-1"></i>Privacy Policy
                                    </span>
                                </Link>
                                <Link to="/route-money/dashboard" className="custom-link">
                                    <span className="d-flex align-items-center" style={{ fontsize: "10px" }}>
                                        <i className="ri-article-fill align-middle me-1"></i>Terms of Service
                                    </span>
                                </Link>
                            </div>
                        </Col>

                        {/* Copyright span */}
                        <Col xs={12} md={6} className="text-center text-md-end">
                            <span>{new Date().getFullYear()} © Route Money.</span>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
